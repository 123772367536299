exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-acceptable-use-policy-js": () => import("./../../../src/pages/acceptable-use-policy.js" /* webpackChunkName: "component---src-pages-acceptable-use-policy-js" */),
  "component---src-pages-access-js": () => import("./../../../src/pages/access.js" /* webpackChunkName: "component---src-pages-access-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-iframe-js": () => import("./../../../src/pages/iframe.js" /* webpackChunkName: "component---src-pages-iframe-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-theatre-js": () => import("./../../../src/pages/our-theatre.js" /* webpackChunkName: "component---src-pages-our-theatre-js" */),
  "component---src-pages-plan-your-visit-js": () => import("./../../../src/pages/plan-your-visit.js" /* webpackChunkName: "component---src-pages-plan-your-visit-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-terms-and-conditions-for-purchase-of-tickets-js": () => import("./../../../src/pages/terms-and-conditions-for-purchase-of-tickets.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-for-purchase-of-tickets-js" */),
  "component---src-pages-the-smartest-giant-in-town-index-js": () => import("./../../../src/pages/the-smartest-giant-in-town/index.js" /* webpackChunkName: "component---src-pages-the-smartest-giant-in-town-index-js" */),
  "component---src-pages-the-smartest-giant-in-town-tickets-account-index-js": () => import("./../../../src/pages/the-smartest-giant-in-town/tickets/account/index.js" /* webpackChunkName: "component---src-pages-the-smartest-giant-in-town-tickets-account-index-js" */),
  "component---src-pages-the-smartest-giant-in-town-tickets-account-login-js": () => import("./../../../src/pages/the-smartest-giant-in-town/tickets/account/login.js" /* webpackChunkName: "component---src-pages-the-smartest-giant-in-town-tickets-account-login-js" */),
  "component---src-pages-the-smartest-giant-in-town-tickets-checkout-js": () => import("./../../../src/pages/the-smartest-giant-in-town/tickets/checkout.js" /* webpackChunkName: "component---src-pages-the-smartest-giant-in-town-tickets-checkout-js" */),
  "component---src-pages-the-smartest-giant-in-town-tickets-index-js": () => import("./../../../src/pages/the-smartest-giant-in-town/tickets/index.js" /* webpackChunkName: "component---src-pages-the-smartest-giant-in-town-tickets-index-js" */),
  "component---src-pages-website-terms-and-conditions-of-use-js": () => import("./../../../src/pages/website-terms-and-conditions-of-use.js" /* webpackChunkName: "component---src-pages-website-terms-and-conditions-of-use-js" */)
}

